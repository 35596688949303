import React,{useState, useEffect } from 'react';
import { stageUrl, webUrl, exchangeUrl } from './../apiUrls/apiUrls';
import {Link, useNavigate} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import axios from 'axios';

//components
import BannerCard from './../components/Home/BannerCard';

import OneStop from './../components/Home/OneStop';
//images
import baner1 from './../assets/images/home-banner/img1.png';
import baner2 from './../assets/images/home-banner/fotor999.png';
import Shape5 from './../assets/images/home-banner/google-pay2.png';
import Shape6 from './../assets/images/home-banner/apple9.png';
import Shape1 from './../assets/images/home-banner/shape2.png';
import Shape3 from './../assets/images/home-banner/shape4.png';

function Home(){
	const [currencies, setCurrencies] = useState([]);
	const [exchangeRate, setExchangeRate] = useState([]);
	const [headings, setHeadings] = useState('');
	const [sellingCurrency, setSellingCurrency] = useState('kes');
	const [buyingCurrency, setBuyingCurrency] = useState('ngn')
	const [testimonials, setTestimonials] = useState([]);
	const [sponsorImages, setSponsorImages] = useState([]);

	console.log('iam testimonials', testimonials)

	useEffect(() => {
		fetchCurrencies();
		fetchheading();
		fetchTestimonials();
		fetchSponsors();
	  }, []);

	const fetchCurrencies = async () => {
		axios.get(`${stageUrl}/currencies/`)
		  .then(response => {
			// Update the currencies state with the retrieved data
			setCurrencies(response.data.data.data);
			if (response.data.data.data.length > 0) {
				const firstCurrency = response.data.data.data[0];
				const secondCurrency = response.data.data.data[1]
				setSelectText([firstCurrency.flag, firstCurrency.currency_name]);
				setSelectionText([secondCurrency.flag, secondCurrency.currency_name]);
			  }
		  })
		  .catch(error => {
			console.error('Error fetching currencies:', error);
		  });
	}
	const fetchheading = async () => {
		try {
		  const response = await axios.get(`${webUrl}/headings`);
		  setHeadings(response.data);
		} catch (error) {
		  console.error('Error fetching headings:', error);
		}
	  };
	const nav = useNavigate();
    const formDetails = (e) => {
        e.preventDefault();
        nav("/contact-us");
    };
	const [selecttext, setSelectText] = useState([]);
	const [selectiontext, setSelectionText] = useState([]);
	
	// const [selectedtext, setSelectedText] = useState('Sell');
	// const [selectingtext, setSelectingText] = useState([coin1,'EURO']);

    const fetchData = async () => {
	try {
		const response = await axios.post(`${exchangeUrl}/${sellingCurrency}/convertAmount`, {
		amount: 1,
		conversionType: 'fiat',
		currencyCoin: buyingCurrency
		});
		setExchangeRate(response.data);
	} catch (error) {
		console.error('Error fetching exchange rate:', error);
	}
	};

	useEffect(() => {
	fetchData();
	}, [sellingCurrency, buyingCurrency]);

	const handleGetOffersClick = () => {
	const url = `https://otc.muda.exchange?sellingCurrency=${sellingCurrency}&buyingCurrency=${buyingCurrency}`;
	window.open(url, '_blank');
	};

	const fetchTestimonials = async () => {
	try {
		const response = await fetch(`${webUrl}/testimonials`);
		const data = await response.json();
		setTestimonials(data);
	} catch (error) {
		console.error('Error fetching testimonials:', error);
	}
	};

	const fetchSponsors = async () => {
		try {
		  const response = await fetch(`${webUrl}/sponsors`);
		  const data = await response.json();
		  const images = data.map((item) => item.sponsor_photo);
		  setSponsorImages(images);
		} catch (error) {
		  console.error('Error fetching sponsors:', error);
		}
	  };
	  

	return(
		<>
			<div className="page-content">				
				<div className="main-bnr style-1">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 text-center">
							<h1 className="" dangerouslySetInnerHTML={{ __html: headings.heading }} />
								{/* <p className="text text-primary " >Buy, sell, trade, and send different currencies with us today. Access the deepest liquidity on the continent today!</p> */}
								<div className="containerz">
								<p className="textz text text-primary" dangerouslySetInnerHTML={{ __html: headings.sub_heading }}></p>
								</div>

								<a href="https://web.muda.exchange" className="btn space-lg btn-gradient btn-shadow btn-primary " >Get Started</a>
								<ul className="image-before">
									<li className="left-img"><img src={baner1} alt="" /></li>
									<li className="right-img"><img src={baner2} alt="" /></li>
								</ul>
							</div>
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
					<img className="bg-shape2" src={Shape1} alt="" />
					<img className="bg-shape3" src={Shape3} alt="" />
					<img className="bg-shape4" src={Shape3} alt="" />
				</div>
				<div className="clearfix bg-primary-light">
					<div className="container">
						<div className="currancy-wrapper">
							<div className="row justify-content-center">
								<BannerCard />
							</div>
						</div>
					</div>
				</div>
				<br></br>
				<section className="clearfix section-wrapper1 bg-primary-light">
				    <div className="container">
						<div className="form-wrapper-box style-1 text-center">
							<div className="section-head " >
								<h4 className="title m-t0">Trade Now</h4>
							</div>
							<form  className="dz-form"  onSubmit={(e) => formDetails(e)}>
								<div className="form-wrapper">
									<div className="flex-1">
										<div className="row g-3">
										{/* <div className="col-xl-3 col-md-6 " >
										<Dropdown className="select-drop">
										<Dropdown.Toggle as="div" className="i-false select-drop-toggle">
											{selectedtext === 'Sell' ? 'Sell' : 'Buy'} <i className="fa-sharp fa-solid fa-angle-down" />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => setSelectedText('Sell')}>Sell</Dropdown.Item>
											<Dropdown.Item onClick={() => setSelectedText('Buy')}>Buy</Dropdown.Item>
										</Dropdown.Menu>
										</Dropdown>
									    </div> */}
									{/* drop down 1 */}
									<div className="col-xl-3 col-md-6 " >
									<Dropdown className="select-drop">
										<Dropdown.Toggle as="div" className="i-false select-drop-toggle">
											<img src={selecttext[0]} alt="" /> {selecttext[1]} <i className="fa-sharp fa-solid fa-angle-down" />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{currencies.map((currency) => (
											<Dropdown.Item key={currency.currency_name} 
											onClick={() => {
												setSelectText([currency.flag, currency.currency_name]);
												setSellingCurrency(currency.currency_name.toLowerCase());
											}}>
												<img src={currency.flag} alt="" /> {currency.currency_name}
											</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
									</div>

									{/* dropdown 2 */}
									<div className="col-xl-3 col-md-6 " >
									<Dropdown className="select-drop">
										<Dropdown.Toggle as="div" className="i-false select-drop-toggle">
											<img src={selectiontext[0]} alt="" /> {selectiontext[1]} <i className="fa-sharp fa-solid fa-angle-down" />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{currencies.map((currenc) => (
											<Dropdown.Item key={currenc.currency_name} onClick={() => {
											setSelectionText([currenc.flag, currenc.currency_name]);
											setBuyingCurrency(currenc.currency_name.toLowerCase());
											}}>
												<img src={currenc.flag} alt="" /> {currenc.currency_name}
											</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
									</div>

									<div className="col-xl-3 col-md-6 " >
										<input name="dzName" type="text" required="" value= {exchangeRate.data ? exchangeRate.data.toFixed(4) : '0'} className="form-control" disabled/>
									</div>
									<div className="col-xl-3 col-md-6 " >
									<a className="btn btn-lg btn-gradient btn-primary btn-shadow" onClick={handleGetOffersClick}>Get Offers</a>
									</div>
									</div>
									</div>
									
								</div>
							</form>
						</div>
					</div>
					<div className="container">
						<div className="content-inner-1">
							<div className="section-head text-center">
								<h2 className="title">Why Trust Us?</h2>
								<p>Discover why businesses choose MUDA to send money across borders.</p>
							</div>
							<div className="widget-title">
								<h4 className="title">Testimonials</h4>
							</div>
							<div className="row">
								{testimonials.map((data, index) => (
									<div className="col-lg-6 m-b30" key={index}>
									<div className="icon-bx-wraper style-2">
										<div className="icon-media">
										<img src={data.photo} alt="" /> {/* Use the updated photo URL */}
										</div>
										<div className="icon-content">
										<h4 className="title">{data.full_name}</h4>
										<p dangerouslySetInnerHTML={{ __html: data.description }}></p>
										</div>
									</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="container">
						<div className="form-wrapper-box text-center">
							<div className="section-head">
							<h4 className="title m-t0">Customers and Partners</h4>
							</div>
							<div className="sponsor-iconz" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
								{sponsorImages.map((image, index) => (
									<img
									key={index}
									src={image}
									alt={`Sponsor ${index}`}
									style={{ width: "200px", height: "100px", objectFit: "contain", margin: "10px" }}
									/>
								))}
							</div>								
						</div>
					</div>
					<br></br>
					<img className="bg-shape1" src={Shape1} alt="" />
					</section>
					<section className="content-inner bg-light icon-section section-wrapper2">
					<div className="container">
						<div className="section-head text-center">
							<h2 className="title">One stop liquidity solution for currency trading in Africa</h2>
						</div>
						<div className="row sp60">
							<OneStop />
						</div>
					</div>
					<img className="bg-shape1" src={Shape1} alt="" />
				</section>
				<div className="container">
				<div className="text-center">
					<div className="section-head text-center">
					<p>Get the Muda mobile app</p>
					<h4 className="title m-t0">We’ve created the mobile app you need for trading Currencies on the go.</h4>
					</div>
					<style>
					{`
						.sponsor-icons {
						display: flex;
						justify-content: center;
						align-items: center;
						}
					`}
					</style>
					<div className="sponsor-icons">
					<a href="https://play.google.com/store/apps/details?id=com.muda.exchange&hl=en_US&gl=US&pli=1">
						<img src={Shape5} alt="click me to download our app" />
					</a>
					<a href="#">
						<img src={Shape6} alt="click me to download our app" />
					</a>
					</div>
					<br></br>
					{/* <div className="section-head text-center">
					<p>Buy, sell, send, receive, and manage your currency securely on your mobile devices.</p>
					</div> */}
				</div>
				</div>

				<br></br>
			</div>		
		</>
	)
} 
export default Home;