import React from 'react';
import PageLayout from '../layouts/PageLayout';

function PrivacyPolicy() {
  return (
    <>
      <div className="page-content">
        <PageLayout desc={false} pageTitle="Privacy Policy" />
        <section className="bg-light content-inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12">
                <div className="bg-light blog-single dz-card sidebar">
                  <div className="dz-info m-b30">
                        <header>
                        <h1>Privacy Policy for Muda Exchange </h1>
                        <p>Updated On: 21st/March/2024</p>
                        </header>

                        <section>
                        <h4>Introduction</h4>
                        <p>Data privacy is of utmost importance to MUDA Ventures Limited <strong>(“MUDA”) </strong>.</p>
                        <p>Protecting the privacy and safeguarding the personal and financial information of muda clients and website visitors is one of our highest priorities.</p>
                        <p>This Privacy Statement sets out the company’s policy on protection of our clients’ privacy.</p>
                        </section>

                        <section>
                        <h4>COLLECTION OF PERSONAL INFORMATION</h4>
                        <p>We collect information required to open an account, to transact business effectively and to safeguard your funds and your privacy. To do this, we gather information to help us assess your needs and preferences. The information we collect directly from you includes information required to communicate with you, including your name, mailing address, telephone number, e-mail and IP addresses. </p>
                        <p>We also collect information relating to your date of birth, national identification card number or passport details, tax registration details and location.</p>
                        <p>In connection with our products and services (as such term is defined in our User Agreement), we may collect and maintain information relating to transactions you effect using the MUDA platform such as converting one currency to another.</p>
                        <p></p>
                        </section>

                        <section>
                        <h4>USE OF PERSONAL INFORMATION</h4>
                        <p>We use personal information only as appropriate to provide you with the quality service and security that MUDA clients the world over appreciate us for. For example, we may use the information collected from you to verify your identity and contact information. We may also use this information to establish and set up your trading account, issue an account number and a secure password, maintain your account activity, and contact you with account information.</p>
                        <p>This information helps us improve our services to you, inform you about additional products, services or promotions that may be of interest to you.</p>
                        <p></p>                               
                        </section>

                        <section>
                        <h4>MUDA AFFILIATES AND PARTNERS</h4>
                        <p>We may share your personal information with affiliates if the information is required to provide the product or service you have requested or to provide you the opportunity to participate in the products or services our affiliates offer. We may also forge partnerships and alliances, which may include joint ventures or joint marketing agreements, with other companies who offer high-quality products and services that might be of value to our clients.</p>
                        <p>In order to ensure that these products and services meet your needs and are delivered in a manner that is useful and relevant, we may share some information with partners, affiliates and alliances. This allows them to better understand the offers that are most relevant and useful. The use of your personal information is limited to the purposes identified in our relationship with the partner or affiliate and subject to the data sharing provisions provided for in line with prevailing data protection laws, regulations and guidelines of the jurisdictions within which we operate.</p>
                        <p></p>
                        </section>
                        
                        <section>
                            <h4>NON-AFFILIATES AND OTHER THIRD PARTIES</h4>
                            <p>MUDA will not sell, license, lease or otherwise disclose your personal information to any third party for any reason, except as described below.</p>
                            <p>We reserve the right to disclose your personal information to third parties where required by law to regulatory, law enforcement or other government authorities. We may also disclose your information as necessary to credit reporting or collection agencies, or when necessary to protect our rights or property. To help us improve our services to you, we may engage another businesses to help us to carry out certain internal functions such as account processing, fulfilment, client service, client satisfaction surveys or other data collection activities relevant to our business.</p>
                            <p>We may also provide a party with client information from our database to help us to analyse and identify client needs and notify clients of product and service offerings. Use of the information shared is strictly limited to the performance of the task we request and for no other purpose.</p>
                            <p>All third parties with which we share personal information are required to protect personal information in a manner similar to the obligations set forth in prevailing and country specific data protection laws and regulations.</p>
                            <p></p>
                        </section>

                        <section>
                            <h4>LIMITATION AND RESTRICTION OF RESPONSIBILITY</h4>
                            <p>If at any time you choose to purchase a product or service offered by another service provider, any personal information you share with that company will no longer be controlled under our Privacy Statement.</p>
                            <p>We are not responsible for the privacy policies or the content of sites we link to and have no control of the use or protection of information provided by you or collected by those sites.</p>
                            <p>Whenever you elect to link to a co-branded website or to a linked website, you may be asked to provide registration or other information.</p>
                            <p>Please note that the information you are providing is going to a third party and you should familiarize yourself with the privacy policy provided by that third party.</p>
                            <p></p>
                        </section>

                        <section>
                            <h4>OPTING OUT</h4>
                            <p>You may direct us not to disclose non-public personal information to certain non-affiliated third parties. To opt out of sharing non-public personal information with non-affiliated third parties, please contact us through support@muda.tech.</p>
                            <p>If you hold more than one account with MUDA, please note that an opt-out election must be made for each separate account.</p>
                            <p></p>
                        </section>

                        <section>
                            <h4>COOKIES</h4>
                            <p>A cookie is a small text file stored on the user's computer for record keeping and security purposes. It allows us to enhance user experience while browsing our website.</p>
                            <p>MUDA issues cookies upon landing on our website, unless the cookie settings on user's browsers are disabled. Please note that by turning off cookie tracking in your browser, some of our services might become unusable such as promotions or registration.</p>
                            <p>Cookies used by us do not contain any personal information nor do they contain account or password information. They merely allow the site to recognize that a page request comes from someone who has already logged on. To administer and improve our website, we may use a third party to track and analyze usage and statistical volume information, including page requests, form requests, and click paths. The third party may use cookies to track behavior and may set cookies on behalf of us. These cookies do not contain any personally identifiable information.</p>
                            <p></p>
                        </section>

                        <section>
                            <h4>CHANGES TO THE POLICY</h4>
                            <p>From time to time, we may update this Privacy Statement. In the event we materially change this Privacy Statement, the revised Privacy Statement will promptly be posted to MUDA’s website and we will post a notice on our website and/ or send you an e-mail informing you of such changes.</p>
                            <p>You agree to accept posting of a revised Privacy Statement electronically on the website as actual notice to you.</p>
                            <p>Any dispute over our Privacy Statement is subject to this notice and our Client Agreement. We encourage you to periodically check back and review this policy so that you always will know what information we collect, how we use it, and to whom we disclose it.</p>
                            <p>If you have any questions that this statement does not address, please contact a Client Services representative at support@muda.tech.</p>
                        </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PrivacyPolicy;
