import React, { useState, useEffect } from 'react';
import { webUrl } from './../apiUrls/apiUrls';
import { Link, useNavigate } from 'react-router-dom';

import PageLayout from './../layouts/PageLayout';
import BlogSidebar from './../components/Blog/BlogSidebar';

const ProductList = () => {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    fetch(`${webUrl}/products`)
      .then(response => response.json())
      .then(data => setApiData(data))
      .catch(error => console.error(error));
  }, []);

  const navigateToNextPage = (product_id) => {
    // Navigate to the next page and pass the product_id as a parameter
    navigate(`/product-details/${product_id}`);
  };

  const descriptionStyle = {
    whiteSpace: 'pre-line',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '6', // Number of lines to show
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const renderFormattedText = (text) => {
    // Replace specific patterns with corresponding JSX elements
    const formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Replace **bold** with <strong>bold</strong>
      .replace(/__(.*?)__/g, '<em>$1</em>'); // Replace __italic__ with <em>italic</em>

    // Render the formatted text as HTML
    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  return (
    <>
      <div className="page-content">
        <PageLayout pageTitle="Our Products" />
        <section className="content-inner bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="row">
                  {apiData.map((item, index) => (
                    <div className="col-lg-12 m-b40" key={index}>
                      <div className="dz-card style-1 blog-half">
                        <div className="dz-media">
                          <Link>
                            <img src={item.product_image} alt="" />
                          </Link>
                          <a className="btn btn-secondary" onClick={() => navigateToNextPage(item.product_id)}>
                            Read More
                          </a>
                        </div>
                        <div className="dz-info">
                          <h4 className="dz-title">
                            <Link to={item.path}>{item.product_name}</Link>
                          </h4>
                          <p className="m-b0" style={descriptionStyle}>
                            {item.product_description && renderFormattedText(item.product_description)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="col-xl-12 col-lg-12 m-b30 m-t30 m-lg-t10">
                    <nav aria-label="Blog Pagination">
                      <ul className="pagination style-1 text-center">
                        <li className="page-item">
                          <Link to={'#'} className="page-link prev">
                            <i className="fas fa-chevron-left"></i>
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link to={'#'} className="page-link active">
                            1
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link to={'#'} className="page-link">
                            2
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link to={'#'} className="page-link">
                            3
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link to={'#'} className="page-link next">
                            <i className="fas fa-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProductList;
