import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { webUrl } from './../apiUrls/apiUrls';

import PageLayout from './../layouts/PageLayout';
import BlogSidebar from './../components/Blog/BlogSidebar';

const BlogList = () => {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  useEffect(() => {
    fetch(`${webUrl}/blogs`)
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setApiData(sortedData);
      })
      .catch(error => console.error(error));
  }, []);

  const navigateToNextPage = (item) => {
    // Store the details of the clicked card (title and description)
    const {
      blog_image,
      blog_image2,
      blog_id,
      blog_title,
      category,
      description,
      blog_author,
      blog_quote,
      quote_author,
      written_date,
      created_at,
    } = item;

    // Navigate to the next page and pass the details as query parameters
    navigate('/blog-details', {
      state: {
        blog_image,
        blog_image2,
        blog_id,
        blog_title,
        category,
        description,
        blog_author,
        blog_quote,
        quote_author,
        written_date,
        created_at,
      },
    });
  };

  const descriptionStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '6', // Number of lines to show
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  // Get current blogs based on pagination
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = apiData.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <>
      <div className="page-content">
        <PageLayout pageTitle="Our Blogs" />
        <section className="content-inner bg-white">
          <div className="container">
            <div className="row ">
              <div className="col-xl-8 col-lg-8">
                <div className="row">
                  {currentBlogs.map((item, index) => (
                    <div className="col-lg-12 m-b40" key={index}>
                      <div className="dz-card style-1 blog-half">
                        <div className="dz-media">
                          <a onClick={() => navigateToNextPage(item)}>
                            <img src={item.blog_image} alt="" />
                          </a>
                          <ul className="dz-badge-list">
                            <li>
                              <Link to={"#"} className="dz-badge">
                                {formatDate(item.created_at)}
                              </Link>
                            </li>
                          </ul>
                          <a className="btn btn-secondary" onClick={() => navigateToNextPage(item)}>
                            Read More
                          </a>
                        </div>
                        <div className="dz-info">
                          <div className="dz-meta">
                            <ul>
                              <li className="post-author">
                                <Link to={"#"}>
                                  <img src={item.blog_image2} alt="" className="me-2" />
                                  <span>{item.category}</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <h4 className="dz-title">
                            <a onClick={() => navigateToNextPage(item)}>{item.blog_title}</a>
                          </h4>
                          <p className="m-b0" style={descriptionStyle} dangerouslySetInnerHTML={{ __html: item.description }}></p>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="col-xl-12 col-lg-12 m-b30 m-t30 m-lg-t10">
                    <nav aria-label="Blog Pagination">
                      <ul className="pagination style-1 text-center">
                        <li className="page-item">
                          <Link to={"#"} className="page-link prev">
                            <i className="fas fa-chevron-left"></i>
                          </Link>
                        </li>
                        {Array.from({ length: Math.ceil(apiData.length / blogsPerPage) }).map((_, index) => (
                          <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                            <Link to={"#"} className="page-link" onClick={() => paginate(index + 1)}>
                              {index + 1}
                            </Link>
                          </li>
                        ))}
                        <li className="page-item">
                          <Link to={"#"} className="page-link next">
                            <i className="fas fa-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4">
                <BlogSidebar />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogList;
