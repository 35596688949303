import React from 'react';
import { HashRouter, Route, Routes  } from 'react-router-dom';

import ScrollToTop from './../layouts/ScrollToTop';
import Header from './../layouts/Header';
import Footer from './../layouts/Footer';
import Home from './Home';
import Pricing from './Pricing';
import Blog from './BlogList';
import OtcRoom from './OtcRoom';
import ContactUs from './ContactUs';
import BlogDetails from './BlogDetails';
import BlogProducts from './BlogProducts';
import Products from './ProductList';
import FaqsPage from './FaqsPage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsandConditions from './TermsandConditions';

function Index(){
	return(
		<HashRouter basename="/">
			<div className="page-wraper">
					<Header />
					<Routes>
						<Route path='/' exact element={<Home />} />
						<Route path='/pricing' exact element={<Pricing />} />
						<Route path='/blog' exact element={<Blog />} />
						<Route path='/faqs' exact element={<FaqsPage />} />
						<Route path='/otc-room' exact element={<OtcRoom />} />
						<Route path='/contact-us' exact element={<ContactUs />} />
						<Route path='/blog-details' exact element={<BlogDetails />} />
						<Route path='/products' exact element={<Products />} />
						<Route path='/product-details/:product_id' exact element={<BlogProducts />} />
						<Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
						<Route path='/terms-and-conditions' exact element={<TermsandConditions />} />
					</Routes>
					<Footer />
				<ScrollToTop />
			</div>
		 </HashRouter>		
	)
} 
export default Index;