import React, { useEffect, useState } from 'react';

import coin1 from './../../assets/images/coins/usa2.png';
import coin3 from './../../assets/images/coins/coin5.png';
import coin4 from './../../assets/images/coins/kenya2.png';
import coin5 from './../../assets/images/coins/ghana.png';
import coin6 from './../../assets/images/coins/nigeria2.png';
import coin7 from './../../assets/images/coins/uganda2.png';

import './BannerCard.css';

const cardData = [
  { image: coin1, title: 'US Dollar', c: 'USD/USD', coin: 'usd', perprice: '1.00', percent: '-0.01' },
  { image: coin4, title: 'KENYAN SHS', c: 'KES/USD', coin: 'kes', perprice: '0.0073', percent: '-0.99' },
  { image: coin6, title: 'NIGERIAN NAIRA', c: 'NGN/USD', coin: 'ngn', perprice: '0.0022', percent: '1.00' },
  { image: coin7, title: 'UGANDAN SHS', c: 'UGX/USD', coin: 'ugx', perprice: '0.00027', percent: '-0.007' },
];

function BannerCard3() {
  const [coinPrices, setCoinPrices] = useState([]);

  useEffect(() => {
    const fetchActiveCoinsConversion = async () => {
      try {
        const response = await fetch(
          'https://ws.muda.exchange/api/buysell/trading/getActiveCoinsConversion',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              currency_id: 'f309f979-efd8-11e9-bb56-02728aeb98de',
            }),
          }
        );
        const data = await response.json();
        console.log(data);

        setCoinPrices(data.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchActiveCoinsConversion();
  }, []);

  return (
    <div className="banner-card-container">
      <div className="banner-card-row">
        {cardData.map((data, index) => {
          const matchingCoin = coinPrices.find((coin) => coin.coin === data.coin);
          const perprice = matchingCoin ? Number(matchingCoin.perprice).toFixed(2) : data.perprice;

          return (
            <div className="banner-card-item wow fadeInUp" data-wow-delay="0.2s" key={index}>
              <div className="icon-bx-wraper style-1 box-hover">
                <div className="icon-media">
                  <img src={data.image} alt="" />
                  <div className="icon-info">
                    <h5 className="title">{data.title}</h5>
                    <span>{data.c}</span>
                  </div>
                </div>
                <div className="icon-content">
                            <ul className="price">
                                <li>
                                    <h6 className="mb-0 amount">{perprice}</h6>
                                    <span className= {`percentage ${index===2 ? "text-green" : "text-red"}`}>{data.percent}%</span>
                                </li>
                                <li>
                                    <span>Latest price</span>
                                    <span>24h change</span>
                                </li>
                            </ul>
                        </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BannerCard3;
