import React, {useState, useEffect} from 'react';
import { webUrl } from './../apiUrls/apiUrls';
import {Link, useLocation} from 'react-router-dom'
import moment from 'moment';

import PageLayout from '../layouts/PageLayout';
import BlogSidebar from '../components/Blog/BlogSidebar';

//images
import blog from './../assets/images/blog/blog-3.png';
import avat3 from './../assets/images/avatar/avatar6.png';
import avat2 from './../assets/images/avatar/avatar6.png';
import avat1 from './../assets/images/avatar/avatar1.jpg';
import pic4 from './../assets/images/blog/blog-3.png';
import pic5 from './../assets/images/blog/pic5.jpg';

const relatedBlog = [
    { image1: pic4, image2: avat2, title:'Trade Instantly At The Best Rates', description: 'Our OTC desk processes over $2million in daily volume, providing users with access to deep liquidity in crypto using their local currency, such as  NGN, UGX, KSHS, GSH, TSHS among others. We support local payment methods such as Mobile Money and bank transfers at affordable rates. Join millions of people using our OTC desk to transfer money worldwide!'},
    { image1: pic5, image2: avat3, title:'Enjoy The Best OTC Trading Rates', description: 'MUDA has a pan-continental established OTC desk that aims to give its clients the ability to transact across several African and international locations.'},
];
const CommentBlog = ({ image, title, author, date, content }) => {
    const formattedDate = moment(date).format('YYYY-MMMM-DD');
    return (
      <>
        <div className="comment-body">
          <div className="comment-author vcard">
            <img className="avatar photo" src={image} alt="" />
          </div>
          <div className="comment-info">
            <div className="title">
              <cite className="fn">{author}</cite>
              <span>{formattedDate}</span>
            </div>
            <p>{content}</p>
            {/* <div className="reply">
              <Link to={"#"} className="comment-reply-link">
                <span><i className="fa-solid fa-share"></i>REPLY</span>
              </Link>
            </div> */}
          </div>
        </div>
      </>
    );
  }

function BlogDetails(){
    const location = useLocation();
    const { state } = location;
    // Access the title and description from the previous page
    const { blog_id, blog_image, blog_image2, blog_title, category, description, blog_author, blog_quote, quote_author, written_date, created_at } = state;
    
    const [isLoading, setIsLoading] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [comments, setComments] = useState([]);

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
      };

    useEffect(() => {
      fetchComments();
    }, []);

    const fetchComments = async () => {
        try {
          const response = await fetch(`${webUrl}/comments/${blog_id}`);
          if (response.ok) {
            const commentsData = await response.json();
            setComments(commentsData);
          } else {
            console.log('Failed to fetch comments');
          }
        } catch (error) {
          console.log('Error occurred while fetching comments:', error);
        }
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const commentData = {
          blog_id: blog_id,
          full_name: fullName,
          email: email,
          message: message
        };
    
        try {
          setIsLoading(true); // Start loading
          const response = await fetch(`${webUrl}/comments`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(commentData)
          });
    
          if (response.ok) {
            // Comment submitted successfully, do something
            fetchComments();
          } else {
            // Comment submission failed, handle the error
            console.log('Comment submission failed');
          }
        } catch (error) {
          // Error occurred during comment submission, handle the error
          console.log('Error occurred during comment submission:', error);
        } finally {
            setIsLoading(false); // Stop loading
          }
      };
    
    return(
        <>
            <div className="page-content">
                <PageLayout desc={false} pageTitle="Over-the-counter(OTC) " />
                <section className="bg-light content-inner">
                    <div className="container">
                        <div className="row ">
                            <div className="col-xl-8 col-lg-8">
                                <div className="bg-light blog-single dz-card sidebar">
                                    <div className="dz-media dz-media-rounded">
                                        <img src={blog_image} alt="" />
                                    </div>
                                    <div className="dz-info m-b30">
                                        <div className="dz-meta">
                                            <ul>
                                                <li className="post-author">
                                                    <Link to={"#"}>
                                                        <img src={blog_image2} alt="" /> 
                                                        <span>BY {blog_author}</span>
                                                    </Link>
                                                </li>
                                                <li className="post-date"><Link to={"#"}>{formatDate(created_at)}</Link></li>
                                                <li className="post-comment"><Link to={"#"}>{category}</Link></li>
                                            </ul>
                                        </div>
                                        <h3 className="dz-title">{blog_title}</h3>
                                        <div className="dz-post-text">
                                            <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                            <blockquote className="wp-block-quote">
                                                <p>{blog_quote}</p>
                                                <cite>{quote_author}</cite>
                                            </blockquote>
                                        </div>
                                        <div className="dz-share-post">
                                            <div className="post-tags">
                                            <h6 className="m-b0 m-r10 d-inline">Tags:</h6>
                                                <Link to={"#"}><span>MUDA</span></Link>
                                                <Link to={"#"}><span>OTC</span></Link>
                                            </div>
                                            <div className="dz-social-icon dark">
                                                <ul>
                                                    <li><a className="fab fa-google" href="mailto:info@muda.tech"></a></li>
                                                    <li><a target="_blank" className="fab fa-instagram" href="https://instagram.com/muda_hq?igshid=YmMyMTA2M2Y="></a></li>
                                                    <li><a target="_blank" className="fab fa-twitter" href="https://twitter.com/mudatech_?t=9xUIcKAWXE2KFGl-wtrkrA&s=09"></a></li>
                                                    <li><a target="_blank" className="fab fa-youtube" href="https://www.youtube.com/@mudatech"></a></li>
                                                </ul>
                                            </div>									
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-title">
                                    <h4 className="title">Related Blog</h4>
                                </div>
                                <div className="row m-b30 m-sm-b10">
                                    {relatedBlog.map((item, ind)=>(
                                        <div className="col-md-6 m-b30" key={ind}>
                                            <div className="dz-card style-1  blog-lg overlay-shine ">
                                                <div className="dz-media ">
                                                    <Link to={"/otc-room"}><img src={item.image1} alt="" /></Link>
                                                </div>
                                                <div className="dz-info">
                                                    <div className="dz-meta">
                                                        <ul>
                                                            {/* <li className="post-author"> */}
                                                                {/* <Link to={"#"}> */}
                                                                    {/* <img src={item.image2} alt="" /> */}
                                                                    {/* <span>By Jemmy</span> */}
                                                                {/* </Link> */}
                                                            {/* </li> */}
                                                            {/* <li className="post-date"><Link to={"#"}> 24 May 2022</Link></li> */}
                                                        </ul>
                                                    </div>
                                                    <h4 className="dz-title"><Link to={"/otc-room"}>{item.title}</Link></h4>
                                                    <p>{item.description}</p>
                                                    <Link to={"/otc-room"} className="btn btn-primary">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}                                   
                                </div>
                                <div className="clear" id="comment-list">
                                    <div className="comments-area style-1 clearfix" id="comments">
                                        <div className="widget-title">
                                            <h4 className="title">Comments</h4>
                                        </div>
                                        <div className="clearfix">
                                        <ol className="comment-list">
                                        {comments.map((comment, index) => (
                                            <li className="comment" key={index}>
                                            <CommentBlog
                                                image={avat3}
                                                // title={comment.full_name}
                                                author={comment.full_name}
                                                date={comment.created_at}
                                                content={comment.message}
                                            />
                                            </li>
                                        ))}
                                        </ol>                                        
                                        </div>
                                        
                                        <div className="widget-title">
                                            <h4 className="title">Leave A Reply</h4>
                                        </div>
                                        <div className="clearfix">
                                            <div className="default-form comment-respond style-1" id="respond">
                                            <form className="comment-form" id="commentform" onSubmit={handleSubmit}>
                                                <p className="">
                                                <label>Name <span className="required">*</span></label>
                                                <input
                                                    type="text"
                                                    name="FirstName"
                                                    placeholder="First Name"
                                                    id="FirstName"
                                                    className="form-control"
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                    required
                                                />
                                                </p>
                                                <p className="">
                                                <label>Email <span className="required">*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder="Email"
                                                    name="email"
                                                    id="email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                                </p>
                                                <p className="comment-form-comment">
                                                <label>Message</label>
                                                <textarea
                                                    rows="8"
                                                    name="Message"
                                                    placeholder="Message"
                                                    id="Message"
                                                    className="form-control"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    required
                                                ></textarea>
                                                </p>
                                                <p className="form-submit">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-skew btn-icon"
                                                    id="submit"
                                                    disabled={isLoading} // Disable the button when loading
                                                >
                                                    {isLoading ? <span>Submitting...</span> : <span>Submit Now</span>}
                                                </button>
                                                </p>
                                            </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <BlogSidebar />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default BlogDetails;