import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { webUrl } from '../../apiUrls/apiUrls';

const categories = [
  { title: 'Home' },
  { title: 'Products' },
  { title: 'Blog' },
  { title: 'OTC Deal Room' },
  { title: 'Exchange' },
];

const tagData = [
  { title: 'OTC' },
  { title: 'OTC Deal Room' },
  { title: 'Cash Link' },
  { title: 'Muda Exchange' },
  { title: 'Blog' },
  { title: 'Contact us' },
  { title: 'Home' },
];

const formatDate = (dateString) => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const BlogSidebar = () => {
  const [recentPosts, setRecentPosts] = useState([]);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const response = await fetch(`${webUrl}/blogs`);
        const data = await response.json();
        const sortedPosts = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const formattedPosts = sortedPosts.map((post) => ({
          image: post.blog_image,
          path: '#/blog',
          title: post.blog_title,
          date: formatDate(post.created_at), // Format the created_at timestamp
        })).slice(0, 3); // Display only the first three posts
        setRecentPosts(formattedPosts);
      } catch (error) {
        console.error('Error fetching recent posts:', error);
      }
    };

    fetchRecentPosts();
  }, []);

  return (
    <>
      <aside className="side-bar sticky-top right">
        <div className="widget widget_categories">
          <div className="widget-title">
            <h4 className="title">Categories</h4>
          </div>
          <ul>
            {categories.map((data, ind) => (
              <li className="cat-item" key={ind}>
                <a to="#">{data.title}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="widget recent-posts-entry">
          <div className="widget-title">
            <h4 className="title">Recent Post</h4>
          </div>
          <div className="widget-post-bx">
            {recentPosts.map((item, ind) => (
              <div className="widget-post clearfix" key={ind}>
                <div className="dz-media">
                  <img src={item.image} alt="" />
                </div>
                <div className="dz-info">
                  <h6 className="title">
                    <a href={item.path}>{item.title}</a>
                  </h6>
                  <div className="dz-meta">
                    <ul>
                      <li className="post-date">
                        <Link to="#">{item.date}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="widget widget_tag_cloud">
          <div className="widget-title">
            <h4 className="title">Popular Tags</h4>
          </div>
          <div className="tagcloud">
            {tagData.map((data, index) => (
              <a to="#" key={index}>
                {data.title}
              </a>
            ))}
          </div>
        </div>
      </aside>
    </>
  );
};

export default BlogSidebar;
