import React, { useState, useEffect, useRef } from 'react';
import PageLayout from './../layouts/PageLayout';
import { webUrl } from './../apiUrls/apiUrls';

import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';

function countWords(text) {
  return text.split(/\s+/).filter(word => word.length > 0).length;
}

function FAQsPage() {
  const [faqs, setFaqs] = useState([]);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [isLoading, SetLoading] = useState(false);

  useEffect(() => {
    async function fetchFAQs() {
      try {
        const response = await fetch(`${webUrl}/faqs`);
        if (response.ok) {
          const data = await response.json();
          setFaqs(data);
        } else {
          console.error('Failed to fetch FAQs');
        }
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    }
    fetchFAQs();
  }, []);

  const toggleExpand = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  const form = useRef();
  const sendEmail = (e) => {
    SetLoading(true)
    e.preventDefault();
    emailjs.sendForm("service_jmx03jp", "template_odobd0v", form.current, "59iJRXKiAxT5F25-3")
       .then((result) => {
       const response = JSON.stringify(result);
        console.log(response);
        if (result.status === 200) {
          SetLoading(false)
          toast("Your Question to Muda Tech has been received successfully A memebr from our Team will get back to you")
          e.target.reset();
        } else if (result.status =! 200){
          SetLoading(false)
          toast("technical error")
        }
       }, (error) => {
       const response = JSON.stringify(error);
         console.log(response);
         if (error.status === 0) {
          SetLoading(false)
          toast(error.text)
        } else if (error.status =! 200){
          SetLoading(false)
          toast("technical error")
        }
       });
  };

  return (
    <>
      <div className="page-content">
      <ToastContainer />
        <PageLayout pageTitle="Frequently Asked Questions" />
        <section className="content-inner bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="row">
                  {faqs.map((faq, index) => (
                    <div className="col-lg-12 m-b40" key={faq.faq_id}>
                      <div className="dz-card style-1">
                        <div className="dz-info">
                          <h4 className="dz-title">{faq.question}</h4>
                          <div
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                            className="m-b0"
                          />
                          {countWords(faq.answer) > 156 && (
                            <button
                              className="btn btn-primary"
                              onClick={() => toggleExpand(index)}
                            >
                              {expandedIndexes.includes(index)
                                ? 'Read Less'
                                : 'Read More'}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="clear col-xl-8 col-lg-8" id="comment-list">
              <div className="comments-area style-1 clearfix" id="comments">
                <div className="widget-title">
                  <h4 className="title">Ask A Question</h4>
                </div>
                <div className="clearfix">
                  <div
                    className="default-form comment-respond style-1"
                    id="respond"
                  >
                    <form className="comment-form" id="commentform" ref={form} onSubmit={sendEmail}>
                      <p className="">
                        <label>
                          Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="First Name"
                          name=" from_email"
                          id="email"
                          className="form-control"
                          required
                        />
                      </p>
                      <p className="">
                        <label>
                          Email <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Email@gmail.com"
                          name="from_name"
                          id="from_name"
                          className="form-control"
                          required
                        />
                      </p>
                      <p className="comment-form-comment">
                        <label>Message</label>
                        <textarea
                          rows="8"
                          name="message"
                          placeholder="Message"
                          id="message"
                          className="form-control"
                          required
                        ></textarea>
                      </p>
                      <p className="form-submit">
                        { 
                          isLoading ? <button className="btn btn-primary"><Spinner animation="border" variant="light" /></button> : <button type="submit" className="btn btn-primary" value="Send">Send Message</button>
                        }
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default FAQsPage;
