import React, {useEffect, useState} from 'react';
import { webUrl } from './../apiUrls/apiUrls';
import PageLayout from '../layouts/PageLayout';


function BlogDetails(){

    const [otcDescription, setOTCDescription] = useState([]);
    useEffect(() => {
      fetchOTCDescription();
    }, []);
  
    const fetchOTCDescription = async () => {
      try {
        const response = await fetch(`${webUrl}/otc_descriptions`);
        const data = await response.json();
        if (response.ok) {
          setOTCDescription(data);
        } else {
          // Handle error case here
          console.error('Failed to fetch OTC description');
        }
      } catch (error) {
        // Handle error case here
        console.error('Failed to fetch OTC description', error);
      }
    };
  

    return(
        <>
            <div className="page-content">
                <PageLayout desc={false} pageTitle="OTC Deal Room" height='70px' />
                <section className="bg-light content-inner" style={{background: "white"}}>
                    <section className="clearfix section-wrapper1 bg-primary-light">
                        <div className="container">
                        <div className="section-head">
                            {otcDescription.map((item, index) => (
                              <p dangerouslySetInnerHTML={{ __html: item.otc_description.replace('WhatsApp here', '<a href="https://wa.me/+256709013034">WhatsApp here</a>') }}></p>
                            ))}
                          </div>

                            <div className="form-wrapper-box style-1 text-center">
                                <div className="section-head " >
                                    <h4 className="title m-t0">Rates</h4>
                                </div>
                                <table className="table dz-table">
                                <thead>
                                    <tr>
                                    <th scope="col">Currency</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>USD</td>
                                    <td>3750</td>
                                    <td>1,000,000</td>
                                    <td>
                                      <a href="https://otc.muda.exchange/market" className="btn btn-lg btn-gradient btn-primary btn-shadow">Buy</a>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td>EUR</td>
                                    <td>4200</td>
                                    <td>500,000</td>
                                    <td>
                                      <a href="https://otc.muda.exchange/market" className="btn btn-lg btn-gradient btn-primary btn-shadow">Buy</a>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td>GBP</td>
                                    <td>3100</td>
                                    <td>750,000</td>
                                    <td>
                                      <a href="https://otc.muda.exchange/market" className="btn btn-lg btn-gradient btn-primary btn-shadow">Buy</a>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                    <br></br>
                </section>
            </div>
        </>
    )
}
export default BlogDetails;
