import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';
import { webUrl } from './../apiUrls/apiUrls';

function BlogProducts() {
  const { product_id } = useParams();
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    fetch(`${webUrl}/products/${product_id}`)
      .then(response => response.json())
      .then(data => setProductData(data))
      .catch(error => console.error(error));
  }, [product_id]);

  const descriptionStyle = {
    whiteSpace: 'pre-line',
  };

  const renderFormattedText = (text) => {
    // Replace specific patterns with corresponding JSX elements
    const formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Replace **bold** with <strong>bold</strong>
      .replace(/__(.*?)__/g, '<em>$1</em>'); // Replace __italic__ with <em>italic</em>

    // Render the formatted text as HTML
    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  return (
    <>
      <div className="page-content">
        <PageLayout desc={false} pageTitle={productData?.product_name} />
        <section className="bg-light content-inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12">
                <div className="bg-light blog-single dz-card sidebar">
                  <div className="dz-info m-b30">
                    {productData && (
                      <>
                        <h3 className="dz-title">{productData.product_name}</h3>
                        <p className="m-b0" style={descriptionStyle}>
                          {renderFormattedText(productData.product_description.replace('WhatsApp channel', '<a href="https://wa.me/+256709013034">WhatsApp channel</a>'))}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default BlogProducts;
